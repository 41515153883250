#root { 
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-family: 'Roboto';
    background-color: #C5D8D1;
    color: #252323;
}

.clickable {
    cursor: pointer;
}

.sign-in {
    font-size: 4rem;
}

.button-col {
    width: 50px;
}

.z-index-0 {
    z-index: 0 !important;
}

.file-drop {
	position: relative;
	border: 5px dashed hsl(208, 66%, 63%);
	border-radius: 1rem;
	overflow: hidden;
	height: 150px;
}
