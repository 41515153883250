.file-release {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgb(211, 192, 216, 0.5);
}

.flow {
	position: absolute;
	bottom: 0;
	height: 40px;
	width: 50px;
}

.flow-left {
	left: -50px;
}

.cover-left {
	background-color: #C5D8D1;
	border-bottom-right-radius: 50%;
	z-index: 11;
}

.switch {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 40px;
	width: 150px;
	border-radius: 15px 15px 0 0;
}

.switch-left {
	left: 20%;
}

.switch-right {
	right: 20%;
}

.flow-right {
	right: -50px;
}

.cover-right {
	background-color: #C5D8D1;
	border-bottom-left-radius: 50%;
	z-index: 11;
}

.shadow-blocker {
	position: absolute;
	background-color: white;
	bottom: -5px;
	height: 10px;
	width: 250px;
	z-index: 10;
}
